import React from 'react';
import * as yup from 'yup';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Cookies from 'js-cookie';
import Form from '../form/form';
import { apiUrl, postRequest } from '../../utils/request';
import { isLoggedIn, logout } from '../../utils/auth';


const codeForm = {
  order: [
    'phone',
    'submit',
  ],
  fields: {
    phone: {
      schema: yup.string().required().trim(),
      customValidation: (field, value, values) => {
        const msg = "You must provide a valid US mobile phone number.";
        if (!value) {
          return msg;
        }
        const phoneNumber = parsePhoneNumberFromString(value, 'US');
        return !phoneNumber || !phoneNumber.isValid() ? msg : null;
      },
      label: "Please enter your mobile # to get started",
      type: "phone",
      // placeholder: "e.g. steve@aol.com",
      // helptext: "Confirm your password to prevent typos.",
    },
    submit: {
      type: 'button',
      submit: true,
      text: "Continue",
      color: "blue",
      block: true,
    },
  },
};



class Code extends React.Component {

  // Ensure we aren't logged in
  componentDidMount() {
    if (isLoggedIn()) {
      logout();
    }
  }

  submitForm = async (data, setErrors) => {
    const url = `${apiUrl}/auth/code`;
    try {
      const rcode = Cookies.get('rcode');
      const _ref = Cookies.get('_ref');
      const result = await postRequest(url, { ...data, rcode, _ref });
      const parsed = window.location.search ? queryString.parse(window.location.search) : {};
      const redir = parsed && parsed.redir ? parsed.redir : '/app/dashboard';
      navigate(`/signin-verify?redir=${redir}&email=${result.email}`);
    } catch (err) {
      setErrors({
        phone: 'You provided an invalid phone number.',
      });
    }
  }

  render() {
    return (
      <Form defaults={{}} submitForm={this.submitForm} form={codeForm} />
    );
  }
}

export default Code;
